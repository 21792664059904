<template>
    <b-row class="justify-content-center">
        <b-col md="6">
            <b-card>
                <b-form v-on:submit.prevent>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                            label="Organization Name"
                            label-for="organizations"
                            >
                            <b-form-input
                                id="organizations"
                                placeholder="Organization Name"
                                v-model="form.organizations.name"
                                required
                            />
                            </b-form-group>
                        </b-col>

                        <!-- submit and reset -->
                        <b-col md="12" class="mt-2">
                            <b-button
                            v-if="permissions.includes('organization-update')"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="success"
                            class="mr-1"
                            @click="updateOrganization()"
                            >
                            Update
                            </b-button>
                            <b-button
                            v-if="permissions.includes('organization-delete')"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="danger"
                            class="mr-1"
                            @click="deleteOrganizations()"
                            >
                            Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        ToastificationContent,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                organizations: ''
            },
            permissions: [],
            errMessage: ''
        }
    },
    created() {
        this.getAllPermissions()
        this.getDetails()
    },
    methods: {
        getAllPermissions() {
            this.permissions = sessionStorage.getItem('permissions')
        },
        getDetails() {
            const id = this.$route.params.id
            this.$http
            .get('organizations/' +id)
            .then((response) => {
                this.form.organizations = response.data.data
                // console.log(response.data.data)
            }).catch((err) => {
                console.log(err.response)
            });
        },
        updateOrganization() {
            const id = this.$route.params.id
            // console.log(id);

            this.$http
            .post("organizations/"+id+"/update", {
                "name": this.form.organizations.name
            })
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success update Organization',
                        variant: 'success',
                    },
                })
                location.href = "/master/organizations"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        deleteOrganizations() {
            const id = this.$route.params.id
            this.$http
            .post("organizations/"+id+"/delete")
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Organization has been deleted',
                        variant: 'danger',
                    },
                })
                location.href = "/master/organizations"
                console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                console.log(errors.response)
            })
        },
    },
}
</script>